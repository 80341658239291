var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector, OnInit } from '@angular/core';
import { FileUploaderComponent } from '@app/shared/common/file-uploader/file-uploader.component';
import { StorageStrategies } from '@app/shared/common/file-uploader/service/file-storage.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UploadServiceProxy } from '@shared/service-proxies/service-proxies';
var SavingUploadModalComponent = /** @class */ (function (_super) {
    __extends(SavingUploadModalComponent, _super);
    function SavingUploadModalComponent(injector, uploadService) {
        var _this = _super.call(this, injector) || this;
        _this.uploadService = uploadService;
        _this.onUploadComplete = new EventEmitter();
        return _this;
    }
    SavingUploadModalComponent.prototype.ngOnInit = function () {
    };
    SavingUploadModalComponent.prototype.fileSelected = function (event) {
        this.selectedFile = event;
    };
    SavingUploadModalComponent.prototype.onUploadFinished = function (uploadResponse) {
        var _this = this;
        var emailData;
        switch (this.storageStrategy) {
            case StorageStrategies.SavingsProofBlob:
            case StorageStrategies.SavingsProofSharepoint:
                emailData = this.getSavingsEmailData(uploadResponse.result[0].url);
                break;
            case StorageStrategies.IncomeProofSharepoint:
                emailData = this.getIncomeEmailData(uploadResponse.result[0].url);
                break;
        }
        if (emailData) {
            this._emailService.sendGenericMail(emailData.subjectLine, emailData.emailBody, emailData.toAddress).subscribe(function () {
                console.log('Goal documentation upload email sent');
                _this.onUploadComplete.next(true);
            });
        }
    };
    SavingUploadModalComponent.prototype.getSavingsEmailData = function (docUrl) {
        var message = 'A user has uploaded savings document for their savings goal.\n\n' +
            'User Guid: ' + this._authGuard.user.profile.sub + '\n' +
            'Name: ' + this._authGuard.user.profile.given_name + '\n' +
            'Surname: ' + this._authGuard.user.profile.family_name + '\n' +
            'Email: ' + this._authGuard.user.profile.email + '\n' +
            'Phone: ' + this._authGuard.user.profile.phone_number + '\n' +
            'Document: ' + docUrl + '\n\n' +
            // 'Income: R' + this.amount + '\n\n' +
            'Please review the documentation and update their claims accordingly.';
        var subjectLine = 'Incomw goal dispute documentation upload - ' + this._authGuard.user.profile.given_name + ' ' + this._authGuard.user.profile.family_name;
        return { subjectLine: subjectLine, emailBody: message, toAddress: 'matsemela.moloi@elevate.co.za' };
    };
    SavingUploadModalComponent.prototype.getIncomeEmailData = function (docUrl) {
        var message = 'A user has disputed their income value for their income confirmation goal\n\n' +
            'User Guid: ' + this._authGuard.user.profile.sub + '\n' +
            'Name: ' + this._authGuard.user.profile.given_name + '\n' +
            'Surname: ' + this._authGuard.user.profile.family_name + '\n' +
            'Email: ' + this._authGuard.user.profile.email + '\n' +
            'Phone: ' + this._authGuard.user.profile.phone_number + '\n' +
            'Document: ' + docUrl + '\n\n' +
            'Income: R' + this.amount + '\n\n' +
            'Please investigate and resolve the dispute.';
        var subjectLine = 'Savings goal documentation upload - ' + this._authGuard.user.profile.given_name + ' ' + this._authGuard.user.profile.family_name;
        return { subjectLine: subjectLine, emailBody: message, toAddress: 'matsemela.moloi@elevate.co.za' };
    };
    return SavingUploadModalComponent;
}(AppComponentBase));
export { SavingUploadModalComponent };
