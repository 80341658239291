import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {AppConsts} from '@shared/AppConsts';
import {FileUploadResponse} from '@app/shared/common/file-uploader/models/file-upload-response';
import {FetchFilesRequest} from '@app/shared/common/file-uploader/models/fetch-files-request';
import {FetchFilesResponse} from '@app/shared/common/file-uploader/models/fetch-files-response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DownloadFilesRequest } from '../models/download-files-request';
import { DeleteFileRequest } from '../models/delete-files-request';

/**
 * Available Storage Strategies supported by the API
 */
export enum StorageStrategies {
    Sharepoint,
    Blob,
    SavingsProofBlob,
    SavingsProofSharepoint,
    BankStatementSharepoint,
    ElevateMeFasterGoalSharepoint,
    IncomeProofSharepoint
}

/**
 * Available Upload Strategies supported by the uploader.
 */
export enum UploadStrategies {
    // Upload each file individually
    Sequential,
    // Upload all files at once
    Simultaneous
}


@Injectable({
    providedIn: 'root'
})
export class FileStorageService {
    constructor(private httpClient: HttpClient) {
    }

    /**
     * Method to submit the upload request to the server using POST
     * @param form The Form Data to be sent to the server.
     */
    public upload(form: FormData) {
        const req = new HttpRequest('POST', this.prepareUrl('Upload'), form, this.prepareHeaders());

        return this.httpClient.request<FileUploadResponse>(req);
    }

    /**
     * Method to fetch all files related to the client from a specific strategy.
     * @param request The request object to be sent.
     */
    public fetchFiles(request: FetchFilesRequest): Observable<Array<FetchFilesResponse>> {
        const req = new HttpRequest<FetchFilesRequest>('POST', this.prepareUrl('Fetch'), request, this.prepareHeaders());

        // return this.httpClient.request<FetchFilesResponse[]>(req);
        return this.httpClient.post<{ result: Array<FetchFilesResponse> }>(this.prepareUrl('Fetch'), request, this.prepareHeaders()).pipe(map(result => result.result));
    }

    // getCoverProposals(brokerGuid: string): Observable<Array<string>> {
    //     let url = `${this.appServiceBaseUrl}/api/services/app/CoverProposals/GetCoverProposals?brokerGuid=${brokerGuid}`;
    //     return this.http.get<{ result: Array<string> }>(url, {}).pipe(map(result => result.result));
    // }

    /**
     * Method to download a file from the server
     * @param request The details of the file to be downloaded
     */
    public downloadFile(request: DownloadFilesRequest): void {
        request.fileName = encodeURIComponent(request.fileName);
        this.httpClient.post(this.prepareUrl('Download'), request, { responseType: 'blob' }).subscribe((blob: Blob) => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = request.fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
        });
    }

    /**
     * Method to delete a file from the server
     * @param request The details of the file to be deleted
     */
    public deleteFile(request: DeleteFileRequest): Observable<any> {
        const url = this.prepareUrl('Remove');
        const httpRequest = new HttpRequest('DELETE', url, request, this.prepareHeaders());
        return this.httpClient.request<void>(httpRequest).pipe(map(response => {
            if (response.type === 4) { // HttpEventType.Response
                return response;
            }
        }));
    }

    /**
     * Prepares the connection URL based on the supplied endpoint.
     * @param endpoint The relative path within the service.
     * @private
     */
    private prepareUrl(endpoint: string): string {
        return `${AppConsts.remoteServiceBaseUrl}/api/services/app/FileStorage/${endpoint}`;
    }

    /**
     * Prepares the common headers to be used in making a request to the server.
     * @private
     */
    private prepareHeaders() {
        return {
            [abp.multiTenancy.tenantIdCookieName]: abp.multiTenancy.getTenantIdCookie(),
            'Authorization': 'Bearer ' + abp.auth.getToken(),
            reportProgress: true
        };
    }
}
