import { HttpEvent } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { FileUploaderComponent } from '@app/shared/common/file-uploader/file-uploader.component';
import { FileUploadResponse } from '@app/shared/common/file-uploader/models/file-upload-response';
import { StorageStrategies } from '@app/shared/common/file-uploader/service/file-storage.service';
import { MyFinancialService } from '@app/shared/services/my-financial.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UploadServiceProxy } from '@shared/service-proxies/service-proxies';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'saving-upload-modal',
    templateUrl: './saving-upload-modal.component.html',
    styleUrls: ['./saving-upload-modal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SavingUploadModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;
    @Output() onUploadComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    storageStrategy: StorageStrategies;
    amount: number | undefined;
    selectedFile: File;
    isLoading: boolean;
    constructor(
        injector: Injector,
        private uploadService: UploadServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
    }

    fileSelected(event: File) {
        this.selectedFile = event;
    }

    onUploadFinished(uploadResponse: any) {
        let emailData: any;

        switch (this.storageStrategy) {
            case StorageStrategies.SavingsProofBlob:
            case StorageStrategies.SavingsProofSharepoint:
                emailData = this.getSavingsEmailData(uploadResponse.result[0].url);
            break;
            case StorageStrategies.IncomeProofSharepoint:
                emailData = this.getIncomeEmailData(uploadResponse.result[0].url);
            break;
        }

        if (emailData) {
            this._emailService.sendGenericMail(emailData.subjectLine, emailData.emailBody, emailData.toAddress).subscribe(() => {
                console.log('Goal documentation upload email sent');
                this.onUploadComplete.next(true);
            });
        }
    }

    getSavingsEmailData(docUrl: string): any {
        const message =
        'A user has uploaded savings document for their savings goal.\n\n' +
        'User Guid: ' + this._authGuard.user.profile.sub + '\n' +
        'Name: ' + this._authGuard.user.profile.given_name + '\n' +
        'Surname: ' + this._authGuard.user.profile.family_name + '\n' +
        'Email: ' + this._authGuard.user.profile.email + '\n' +
        'Phone: ' + this._authGuard.user.profile.phone_number + '\n' +
        'Document: ' + docUrl + '\n\n' +
        // 'Income: R' + this.amount + '\n\n' +
        'Please review the documentation and update their claims accordingly.';

        const subjectLine = 'Incomw goal dispute documentation upload - ' + this._authGuard.user.profile.given_name + ' ' + this._authGuard.user.profile.family_name;

        return {subjectLine : subjectLine, emailBody: message, toAddress: 'matsemela.moloi@elevate.co.za'};
    }

    getIncomeEmailData(docUrl: string): any {
        const message =
        'A user has disputed their income value for their income confirmation goal\n\n' +
        'User Guid: ' + this._authGuard.user.profile.sub + '\n' +
        'Name: ' + this._authGuard.user.profile.given_name + '\n' +
        'Surname: ' + this._authGuard.user.profile.family_name + '\n' +
        'Email: ' + this._authGuard.user.profile.email + '\n' +
        'Phone: ' + this._authGuard.user.profile.phone_number + '\n' +
        'Document: ' + docUrl + '\n\n' +
        'Income: R' + this.amount + '\n\n' +
        'Please investigate and resolve the dispute.';

        const subjectLine = 'Savings goal documentation upload - ' + this._authGuard.user.profile.given_name + ' ' + this._authGuard.user.profile.family_name;

        return {subjectLine : subjectLine, emailBody: message, toAddress: 'matsemela.moloi@elevate.co.za'};
    }
}
