﻿import { GetQuestionariesModule, IBankDetails } from '@app/shared/models_elevate/models';
import { StringFilterUI } from '@syncfusion/ej2-angular-grids';
import { Product } from '../../../app/shared/FactoryResources/ProductFactory/Product';
import { KeyedCollection } from '@shared/utils/dictonary-helper';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

export declare module ProductModule {
    export interface ProductDetailSummary {
        productName: string;
        items: Array<ProductDetailsSummaryItem>;
    }

    export interface ProductDetailsSummaryItem {
        title: string;
        subDesc: string;
        pointsList: Array<{
            item: string;
            list: Array<string>;
        }>;
    }
}

export declare module HealthRiskAssessmentModule {
    export interface Category {
        key: string;
        title: string;
        description: string;
        chart: string;
        percentage: number;
        average: number;
        isShown: boolean;
        claimType: string;
        status: string;
        report: CategoryRepory;
        questionnaire: Questionnaire;
    }

    export interface CategoryRepory {
        descriptions: Array<ReportDescription>;
        positiveOutcomes: Array<string>;
        negativeOutcomes: Array<string>;
    }

    export interface ReportDescription {
        title: string;
        content: Array<string>;
        list: Array<string>;
    }

    export interface Questionnaire {
        version: string;
        key: string;
        title: string;
        intros: Array<Intro>;
        phases: Array<Phase>;
    }

    export interface Question {
        reference?: Reference;
        key: string;
        type: string;
        formula?: Formula;
        title: string;
        weight: number;
        defaultValue?: string;
        isScored: boolean;
        isVisible: boolean;
        answers: Array<Answer>;
        answersGiven: Array<Answer>;
        triggers?: Array<Triggers>;
        reweight: Reweight;
        score?: Score;
        report?: Report;
        suffix?: string;
        tooltip?: string;
    }

    export interface Report {
        positive: ReportSetup;
        negative: ReportSetup;
    }

    export interface ReportSetup {
        scores: Array<string | number>;
        message: string;
    }

    export interface Reweight {
        questionKeys: Array<string>;
        values: Array<string | number>;
        reweightValue: number;
        hasBeenReweighted: boolean;
    }

    export interface Reference {
        questionnaireKey: string;
        questionKey: string;
    }

    export interface Formula {
        expression: string;
        fallbackExpression?: string;
        evaluatedExpression?: string;
    }

    export interface Answer {
        title: string;
        value: number | string;
        range?: Array<number>;
        excludeOthers?: boolean;
    }

    export interface Intro {
        title: string;
        description: string;
        footer: string;
    }

    export interface Phase {
        key: string;
        isVisible: boolean;
        title?: string;
        questions: Array<Question>;
    }

    export interface Triggers {
        key: string;
        values?: Array<number | string>;
        range?: Array<number>;
    }

    export interface CreateHealthRiskAssessment {
        id?: string;
        questionnaireKey: string;
        entityNumber: string;
        data: {
            healthRiskAssessment: Questionnaire;
            userDetails: UserDetails;
            score: Score;
            isCompleted: boolean;
        };
    }

    export interface PeopleLikeYouScore {
        id: string;
        hraCategory: string;
        startAge: number;
        endAge: number;
        gender: string;
        score: number;
    }

    export interface HealthRiskAssessmentOverview {
        questionnaireKey: string;
        isCompleted: boolean;
        achievedScore: number;
        maxScore: number;
        entityNumber: string;
        createdDate: Date;
    }

    export interface Score {
        achieved: number;
        max: number;
    }

    export interface UserDetails {
        entityNumber: string;
        age: number;
        gender: string;
        smokerStatus: string;
        netIncome: number;
        grossIncome: number;
        education: string;
        occupation: string;
    }
}

export declare module ProductListModule {

    export interface GuaranteeConfig {
        GuaranteeType: string;
        GuaranteePeriod: number;
        MaximumAdHocIncreasePercentage: number;
        MaximumAdHocIncreaseAdjustor: number;
        GuaranteePeriodAdjustor: boolean;
    }

    export interface GuaranteedAssurability {
        AssurabilityType: string;
        Percentage: number;
        Term: number;
    }

    export interface ConstraintConfig {
        MinAge: number;
        MaxAge: number;
        HIVRequirement: string;
        MinGrossIncome: number;
        Gender: string;
        GuaranteeConfig: GuaranteeConfig;
        MinBenefitAmount: number;
        MaxBenefitAmount: number;
        MinBenefitOverallEscalation: number;
        MaxBenefitOverallEscalation: number;
        MinBenefitAdditionalEscalation: number;
        MaxBenefitAdditionalEscalation: number;
        MinOfficePremium: number;
        MaxOfficePremium: number;
        MinPremiumOverallEscalation: number;
        MaxPremiumOverallEscalation: number;
        MinPremiumAdditionalEscalation: number;
        MaxPremiumAdditionalEscalation: number;
        GuaranteedAssurabilitys: GuaranteedAssurability[];
    }

    export interface BenefitPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface PremiumPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface EventSet {
        ShortCode: string;
        Description: string;
    }

    export interface ProductConfig {
        UID: string;
        Name: string;
    }

    export interface ProductRequirements2 {
        RequirementType: string;
        RiderPolicy: boolean;
        Accelerator: boolean;
        ProductConfig: ProductConfig;
    }

    export interface ProductConfig2 {
        UID: string;
        Name: string;
    }

    export interface ProductRequirement {
        ProductConfig_2: ProductConfig2;
    }

    export interface IProductListModel {
        UID: string;
        Name: string;
        ConstraintConfig: ConstraintConfig;
        BenefitPatternSets: BenefitPatternSet[];
        PremiumPatternSets: PremiumPatternSet[];
        EventSets: EventSet[];
        Category: string;
        DisplayName: string;
        ProductRequirements_2: ProductRequirements2[];
        ProductRequirements: ProductRequirement[];
        riderProducts?: IProductListModel[];
        isMainProduct?: boolean;
        isProductQuoted?: boolean;
        premiumAmount?: number;
        AssuredValue?: number;
        selected?: boolean;
        producQuotedId?: string;
    }

    export interface IProductListModelResult {
        result: IProductListModel[];
    }

    export interface ISelectedProductDetails {
        product?: Product;
        productedQuotedId?: string;
        productName?: string;
        quoteId?: string;
        premiumAmount?: number;
        productId?: string;
        productCategory?: string;
        assuredValue?: number;
        riderProductDetails?: any;
        prdouctRef?: IProductListModel;
        riderProductRef?: IProductListModel[];
        isMainProduct?: boolean;
        bankingDetailsAdded?: boolean;
        benifiieryAdded?: boolean;
        beneficiaries?: BenficiaryModule.IBeneficiaryModel[];
        bankingDetails?: IBankDetails;
    }
}

export declare module BenefitModule {

    export interface IBCEFixed {
        BCOverallEscalation: number;
        BCEscalationFrequency: string;
        BCEscalationTerm: number;
        BCEscalationCeaseAge: string;
    }

    export interface IIndex {
        Name: string;
    }

    export interface IBCEIndexLinked {
        BCAdditionalEscalation: number;
        BCEscalationFrequency: string;
        BCEscalationCeaseAge: string;
        Index: IIndex;
    }

    export interface IBCEReviewable {
        ReviewPeriod: string;
    }

    export interface IBeforeClaimEscalation2 {
        BCE_Fixed?: IBCEFixed;
        BCE_IndexLinked?: IBCEIndexLinked;
        BCE_Reviewable?: IBCEReviewable;
    }

    // tslint:disable-next-line: no-empty-interface
    export interface InClaimEscalation2 {
    }

    export interface IBenefitPattern {
        UID?: string;
        PaymentFrequency?: string;
        MetricLinkedBenefit?: string;
        BeforeClaimEscalation?: string;
        DeferredPeriod?: number;
        Deleted?: boolean;
        InClaimEscalation?: string;
        CoverInForceCeaseAge?: string;
        MaxCoverInForceTerm?: string;
        BeforeClaimEscalation_2?: IBeforeClaimEscalation2;
        InClaimEscalation_2?: any;
        selected?: boolean;
    }

    export interface IBenefitModel {
        Selected?: boolean;
        UID?: string;
        FriendlyIdentifier?: string;
        BenefitPatterns?: IBenefitPattern[];
    }

    export interface IBenefitModelResult {
        result: IBenefitModel;
    }

}

export declare module PremiumPatternModule {

    export interface PremiumPattern {
        UID?: string;
        TaxPercentage?: number;
        PremiumEscalationType?: string;
        PaymentFrequency?: string;
        CeaseAge?: string;
        FixedIncreasePremium?: IFixedIncreasePremium;
        IndexLinkedPremium?: IIndexLinkedPremium;
        selected?: boolean;
    }

    export interface IPremiumPatternModel {
        UID?: string;
        FriendlyIdentifier?: string;
        PremiumPatterns?: PremiumPattern[];
    }

    export interface IPremiumPatternModelResult {
        result: IPremiumPatternModel;
    }

    export interface IFixedIncreasePremium {
        EscalationFrequency: string;
        EscalationTerm: number;
        OverallEscalation: number;
    }

    export interface IIndexLinkedPremium {
        AdditionalEscalation: number;
        EscalationFrequency: string;
        EscalationTerm: number;
        Index: Index;
    }

    export interface Index {
        Name: string;
    }

    export interface IRateTableModel {
        BenefitPatterns: string[];
        PremiumPatterns: any[];
    }
}

export declare module RateTableModule {

    export interface IProductConfig {
        UID: string;
    }

    export interface IEventSet {
        ShortCode: string;
    }

    export interface IRatingFactor {
        ShortCode: string;
        Description: string;
    }

    export interface IBenefitPattern {
        UID: string;
    }

    export interface IPremiumPattern {
        UID: string;
    }

    export interface IRateTableModel {
        UID: string;
        FriendlyIdentifier: string;
        BaseRate: number;
        ProductConfig: IProductConfig;
        EventSets: IEventSet[];
        RatingFactors: IRatingFactor[];
        BenefitPatterns: IBenefitPattern[];
        PremiumPatterns: IPremiumPattern[];
    }
}

export interface CommissionPattern {
    brokerIdpGuid: string;
    brokerAdminGuid: string;
    brokerageShortName: string;
    commissionType: string;
    percentageOfMaxCommission: number;
    primaryCommissionPaymentMonths: string;
    primaryCommissionPaymentPercentages: string;
    secondaryCommissionPaymentMonths: string;
    secondaryCommissionPaymentPercentages: string;
    recurringBaseCommissionPercentage: number | null;
    recurringCommissionAges: string;
    recurrintCommissionPercentages: string;
    isActive: boolean;
    informationDate: Date;
}

export declare module CommissionModule {
    export interface BrokerCommission {
        commissionValues: Array<CommissionValue>;
        primaryCommission: Array<CommissionEntry>;
        secondaryCommission: Array<CommissionEntry>;
        commissionPattern: CommissionPattern;
    }

    export interface CommissionValue {
        actualElevateAsAndWhenCommission: number;
        actualElevatePrimaryCommission?: null;
        brokerIdpGuid: string;
        commissionAmount: number;
        commissionPaymentDate: string;
        percentageOfActualElevateCommissionPaid?: null;
        premiumAmountUponWhichCommissionIsBased: number;
        quoteGuid: string;
        commissionPaymentDateIndex: number;
    }

    export interface CommissionEntry {
        commission: number;
        month: number;
        percentage: number;
        premium: number;
    }
}

export declare module BenificeryModule {
    export interface IBenificaryFormModel {
        title?: ITitleSalutation;
        firstName?: string;
        lastName?: string;
        idNumberString?: string;
        knownAs?: string;
        relatonShipToPolicyHolder?: RelationshipEnum;
        cellNumber?: string;
        emailAddress?: string;
        allocationPercentage?: number;
    }

    export interface ITitleSalutation {
        text?: string;
        value?: number;
    }


    export enum RelationshipEnum {
        Husband,
        Wife,
        Father,
        Mother
    }

    export interface ProductModel {
        name?: string;
        beneficiaryModel?: BenificeryModule.IBenificaryFormModel;
    }

    export interface IBeneficiaryForQuote {
        Beneficiary: string;
        AllocationPercentage: number;
        AssuredType: string;
    }
}

export declare module BeneficieryOutputModule {

    export interface BeneficieryOutput {
        Beneficiary: string;
        AllocationPercentage: number;
        AssuredType: string;
    }

    export interface BeneficieryOutputResult {
        result: BeneficieryOutput[];
    }

}



export declare module BenficiaryModule {
    export interface IBeneficiaryModel {
        entityNumber?: string;
        id?: string;
        productName?: string;
        salutation?: string;
        firstName?: string;
        lastName?: string;
        identificationType?: string;
        idNumber?: string;
        relationshipToPolicyHolder?: string;
        phoneNumber?: string;
        email?: string;
        allocationPercentage?: number;
        policyGuid?: string;
        isPrimary?: boolean;
    }

    export interface IPolicicyBeneficiary {
        policyUid: string;
        policyName: string;
        quoteUid: string;
        products: Array<{
            productName: string,
            productUid: string,
            beneficiaries: Array<BenficiaryModule.IBeneficiaryModel>,
            deletedBeneficiaries: Array<string>,
            hasChanged: boolean,
            isMainProduct: boolean
        }>;
    }

    export interface IProductConfig {
        UID: string;
        name: string;
        displayName: string;
        category: string;
    }
}

export declare module QuoteModule {
    export interface INeedsAssessmentSumAssuredLevel {
        quircCategory: string;
        mendixCategory: string;
        noNeedsAssessmentSumAssured: number;
        fullNeedsAssessmentSumAssured: number;
        excluded: string;
        partialNeedsAssessmentSumAssured: number;
    }

    export interface IQuoteDetails {
        BankAccHolder: string;
        UID: string;
        QuoteNumber: string;
        QuoteStatus: string;
        LastStatusChangeDate: string;
        InceptionDate: string;
        LifeAssuredAssuredType: string;
        CalculatedPremium: number;
        BankName: string;
        BankBranchCode: string;
        BankAccHolderInitials?: string;
        BankAccHolderFirstName?: string;
        BankAccHolderLastName?: string;
        BankAccHolderIDNumber?: string;
        BankAccNumber: string;
        BankCheckCDV: boolean;
        BankAccType: string;
        BankPaymentMethod: string;
        BankCollectionDate: number;
        ProductQuoteds?: IProductQuoted[];
        Person_MainMember: IPerson;
        Person_LifeAssured: IPerson;
        Person_Broker: IPerson;
        IncrementQuote: boolean;
        ReplacementQuote: boolean;
        RollbackQuote: boolean;
    }

    export interface IQuoteDetailsResult {
        result: IQuoteDetails;
    }

    export interface ExtendedQuote extends IQuoteDetails {
        policyNumber: string;
    }

    export interface IPerson {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductQuoted {
        IsMainProduct: boolean;
        AssuredValue: number;
        PremiumValue: number;
        ProductConfig: IProductConfig;
        EventSet: IEventSet;
        BenefitPattern: IBenefitPattern;
        PremiumPattern: IBenefitPattern;
    }

    export interface IBenefitPattern {
        UID: string;
    }

    export interface IEventSet {
        ShortCode: string;
    }

    export interface IProductConfig {
        UID: string;
        Name: string;
    }
}

export declare module PoliciesModule {
    export interface IPolicy {
        policyGuid: string;
        policyNumber: string;
        quoteGuid: string;
        quoteNumber: string;
        inceptionDate: string;
        policyStatus: string;
        brokerGuid: string;
        brokerConsultantsAssigned: string;
        lifeAssuredGuid: string;
        premiumPayerGuid: string;
        rewardSubAccountGuid: string;
        lastUpdatedDate: Date;
        products: Array<IPolicyProduct>;
        policyCession?: IPolicyCession;
        policyLapsed: boolean;
        policyLapsedDate?: Date;
    }

    export interface IPolicyProduct {
        productGuid: string;
        policyGuid: string;
        productName: string;
        sumAssured: number;
        premium: number;
        benefitPatternGuid: string;
        premiumPatternGuid: string;
        isMainProduct: boolean;
        status: string;
    }

    export interface IPolicyCession {
        policyCessionGUID: string;
        policyGUID: string;
        cessionActive: boolean;
        cessionDateTime: Date;
        cessionDescription: string;
        creationDateTime: Date;
    }

    export interface IBeneficiary {
        AllocationPercentage: number;
        BeneficiaryAssuredType: string;
        Person: {
            Identifier: string
        };
    }

    export interface IExclusion {
        ExclusionCode: string;
        CheckCode: string;
        DisplayName: string;
        ConditionName: string;
        Description: string;
    }

    export interface IIndividualExclusionSetAPI {
        DisplayName: string;
        CategoryCode: string;
        CategoryName: string;
        Description: string;
        Status: string;
        Identifier: string;
        FriendlyIdentifier: string;
        Exclusions: IExclusion[];
    }

    export interface IEvent {
        EventCode: string;
        DisplayName: string;
        ConditionName: string;
        Description: string;
        TierName: string;
        TierPercentage: number;
        SurvivalPeriod: number;
    }

    export interface IClaimSet {
        Identifier: string;
        StartDate?: Date;
        EndDate?: Date;
        Status: string;
        ClaimPatterns?: ClaimPattern;
        Event: Event;
        ProductSelected?: IProductSelected;
    }

    export interface IProductSelected {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface ClaimPattern {
        Identifier: string;
        StartDate: Date;
        Severity: number;
        Active: boolean;
    }

    export interface ClaimLine {
        Identifier: string;
        BenefitPatternGUID: string;
        PaymentStatus: string;
        CanPay: boolean;
        Amount: number;
        EffectiveDate: Date;
        MasterStatus: string;
    }

    export interface IPerson {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductSelectedAPI {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface ICoverInstanceAPI {
        Value: number;
        Premium: number;
        CoverStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        ProductSelectedAPI: IProductSelectedAPI;
    }

    export interface RoleAPI {
        RoleType: string;
        AssuredType: string;
        RoleStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        Person: IPerson;
        CoverInstanceAPIs: ICoverInstanceAPI[];
    }

    export interface ICoverInstanceAPI2 {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IEvent2 {
        EventCode: string;
        DisplayName: string;
        ConditionName: string;
        Description: string;
        TierName: string;
        TierPercentage: number;
        SurvivalPeriod: number;
    }

    export interface IEventSet {
        ShortCode: string;
        DisplayName: string;
        CategoryCode: string;
        CategoryName: string;
        Description: string;
        WaitingPeriod: number;
        Events: IEvent2[];
    }

    export interface IBCEFixed {
        BCOverallEscalation: number;
        BCEscalationFrequency: string;
        BCEscalationTerm: number;
        BCEscalationCeaseAge: string;
    }

    export interface IBeforeClaimEscalation2 {
        BCE_Fixed: IBCEFixed;
        BCE_IndexLinked: IBCEIndexLinked;
    }

    export interface IBCEIndexLinked {
        BCAdditionalEscalation: number;
        Index: IIndex;
    }

    export interface IBenefitPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface IBenefitPattern {
        UID: string;
        PaymentFrequency: string;
        MetricLinkedBenefit: string;
        BeforeClaimEscalation: string;
        DeferredPeriod: number;
        CoverInForceCeaseAge: string;
        MaxCoverInForceTerm: string;
        BeforeClaimEscalation_2: IBeforeClaimEscalation2;
        //InClaimEscalation_2: IInClaimEscalation2;
        BenefitPatternSet: IBenefitPatternSet;
    }

    export interface IFixedIncreasePremium {
        EscalationFrequency: string;
        OverallEscalation: number;
        EscalationTerm: number;
    }

    export interface IPremiumPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface IIndex {
        Name: string;
        CheckName: string;
    }

    export interface IIndexLinkedPremium {
        EscalationFrequency: string;
        AdditionalEscalation: number;
        EscalationTerm: number;
        Index: IIndex;
    }

    export interface IPremiumPattern {
        UID: string;
        TaxPercentage: number;
        PremiumEscalationType: string;
        PaymentFrequency: string;
        CeaseAge: string;
        FixedIncreasePremium?: IFixedIncreasePremium;
        IndexLinkedPremium?: IIndexLinkedPremium;
        PremiumPatternSet: IPremiumPatternSet;
    }

    export interface IBespokeProductAPI {
        BespokeProductStatus: string;
        UnderwritingLoading: number;
        EventSet: IEventSet;
        BenefitPattern: IBenefitPattern;
        PremiumPattern: IPremiumPattern;
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IUnderwritingRule {
        ShortCode: string;
    }

    export interface IRatingFactor {
        ShortCode: string;
    }

    export interface IConstraintConfig {
        MinBenefitAmount: number;
        MaxBenefitAmount: number;
        MinBenefitOverallEscalation: number;
        MaxBenefitOverallEscalation: number;
        MinBenefitAdditionalEscalation: number;
        MaxBenefitAdditionalEscalation: number;
        MinOfficePremium: number;
        MaxOfficePremium: number;
        MinPremiumOverallEscalation: number;
        MaxPremiumOverallEscalation: number;
        MinPremiumAdditionalEscalation: number;
        MaxPremiumAdditionalEscalation: number;
        MinAge: number;
        MaxAge: number;
        HIVRequirement: string;
        MinGrossIncome: number;
        Gender: string;
        MaxRecurringPayments: number;
        UnderwritingRules: IUnderwritingRule[];
        RatingFactors: IRatingFactor[];
    }

    export interface IProductConfig {
        ConstraintConfig: IConstraintConfig;
        UID: string;
        Name: string;
        DisplayName: string;
    }

    export interface IProduct {
        ProductConfig: IProductConfig;
    }

    export interface IProductSelectedAPI2 {
        Individual: boolean;
        Identifier: string;
        FriendlyIdentifier: string;
        CoverInstanceAPIs: ICoverInstanceAPI2[];
        BespokeProductAPIs: IBespokeProductAPI[];
        Product?: IProduct;
    }

    export interface ICoverInstanceAPI3 {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductPackageAPI {
        ReferenceNumber: string;
        InceptionDate: Date;
        ProductPackageStatus: string;
        RoleAPIs: RoleAPI[];
        ProductSelectedAPIs: IProductSelectedAPI2[];
        CoverInstanceAPIs: ICoverInstanceAPI3[];
    }

    export interface Quote {
        UID: string;
        QuoteNumber: string;
        InceptionDate: Date;
    }

    export interface NonIndividualExclusionSet {
        ShortCode: string;
        CheckCode: string;
        DisplayName: string;
        CategoryCode: string;
        CategoryName: string;
        Description: string;
    }

    export interface IUniqueProducts {
        productId: string;
        productName: string;
        PremiumAmount: number;
        CoverAmount: number;
    }

    export interface IPolicyModel {
        PolicyStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        IndividualExclusionSetAPIs?: IIndividualExclusionSetAPI[];
        ClaimSets: IClaimSet[];
        ProductPackageAPIs: IProductPackageAPI[];
        Quote: Quote;
        NonIndividualExclusionSets: NonIndividualExclusionSet[];
        productList?: IUniqueProducts[];
        SubAccount: {
            UID: string;
            SubAccountLongName: string;
        };
        RewardSubAccount: {
            UID: string;
            SubAccountLongName: string;
        };
    }

    export interface IPolicyModelResult {
        result: IPolicyModel;
    }

}

export const defaultOccupationClass = (occupationClass: string): string => {
    try {
        if (occupationClass) {
            const level = +(occupationClass.substring(occupationClass.length - 1));

            if (level > 4) {
                return 'Class_4';
            }

            return occupationClass;
        }
    } catch (e) {
        return occupationClass;
    }
};

export declare module PersonModule {

    export interface MedicalScheme {
        id: number;
        medicalSchemeName: string;
    }

    export interface IPersonBankingDetails {
        AccountHolderFirstName: string;
        AccountHolderIDNumber: string;
        AccountHolderInitials: string;
        AccountHolderLastName: string;
        AccountNumber: string;
        AccountType: string;
        Active: boolean;
        AmountLimitType: string;
        AmountRangeLimit: number;
        Bank: string;
        BranchCode: string;
        CDVChecked: boolean;
        DayOfMonthDebit: number;
    }

    export interface IPersonAPI {
        BankingDetails?: Array<IPersonBankingDetails>;
        AddressAPIs: Array<IPersonAddress>;
        IDNumber?: string;
        IdentificationType?: string;
        FirstName?: string;
        Initials?: string;
        Surname?: string;
        Gender?: string;
        DateOfBirth?: string;
        Age?: number;
        ContactNumber?: string;
        Email?: string;
        PersonStatus?: string;
        Identifier?: string;
        FriendlyIdentifier?: string;
    }

    export interface IRatingFactor {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IRatingFactor2 {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IRatingFactor3 {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IPolicyAPI {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductPackageAPI {
        PolicyAPIs: IPolicyAPI[];
    }

    export interface IProductConfig {
        UID: string;
        Category: string;
        DisplayName: string;
    }

    export interface IProduct {
        ProductConfig: IProductConfig;
    }

    export interface IProductSelectedAPI {
        Identifier: string;
        FriendlyIdentifier: string;
        ProductPackageAPI: IProductPackageAPI;
        Product: IProduct;
    }

    export interface ICoverInstanceAPI {
        Value: number;
        Premium: number;
        CoverStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        ProductSelectedAPI: IProductSelectedAPI;
    }

    export interface IRoleAPI {
        RoleType: string;
        AssuredType: string;
        RoleStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IPersonAddress {
        Address_1: string;
        Address_2: string;
        Address_3: string;
        Address_4: string;
        Identifier: string;
        PostalCode: string;
    }

    export interface IPersonModel {
        AdditionalContactNumber: string;
        AdditionalEmail: string;
        GrossIncome: number;
        PersonAPI: IPersonAPI;

        RatingFactor: IRatingFactor;
        RatingFactor_2: IRatingFactor2;
        RatingFactor_3: IRatingFactor3;
        CoverInstanceAPIs: ICoverInstanceAPI[];
        RoleAPIs: IRoleAPI[];
        EffectiveDate?: Date;
        MedicalSchemeOptionPUT?: string;
        RegionProvincePUT?: string;
        EmployerLocalOfficePUT?: string;
        SmokingStatusPUT?: string;
        OccupationCategoryPUT?: string;
        SocioEconomicClassPUT?: string;
        SocioEconomicClass?: string;
        SmokingStatus?: string;
        Occupation?: string;
        Education?: string;
        IDP_GUID?: string;
    }

    export interface IPersonModelResult {
        result: IPersonModel;
    }

    export interface AddressAPI {
        Address_1?: string;
        Address_2?: string;
        Address_3?: string;
        Address_4?: string;
        PostalCode?: string;
        Identifier?: string;
        FriendlyIdentifier?: string;
    }

    export interface DebitMandateDTO {
        IDNumber: string;
        Initials: string;
        Name: string;
        Surname: string;
        AccountNumber: string;
        Bank: string;
        BranchCode: string;
        AccountType: string;
        AmountLimitType: string;
        AmountRangeLimit: number;
        DayOfMonthDebit: number;
        CDVChecked: boolean;
        Active: boolean;
    }

    export interface PersonAPI {
        IDNumber?: string;
        IdentificationType?: string;
        FirstName?: string;
        Initials?: string;
        Surname?: string;
        Gender?: string;
        DateOfBirth?: any;
        ContactNumber?: string;
        Email?: string;
        AddressAPIs?: AddressAPI[];
        BankingDetails?: IPersonBankingDetails[];
        PassportNumber?: string;
        RegistrationNumber?: string;
        DebitMandateDTOs?: DebitMandateDTO[];
    }

    export interface IPersonInput {
        AdditionalContactNumber?: string;
        AdditionalEmail?: string;
        PersonAPI?: PersonAPI;
        EffectiveDate?: any;
        GrossIncome?: number;
        MedicalSchemeOptionPUT?: string;
        RegionProvincePUT?: string;
        EmployerLocalOfficePUT?: string;
        SmokingStatusPUT?: string;
        OccupationCategoryPUT?: string;
        SocioEconomicClassPUT?: string;
        Education?: string;
        IDP_GUID?: string;
    }





}

export module RatingFactorModule {
    export class PersonRatingFactor {
        age: number;
        smoker: string;
        occupational: string;
        socials: string;
        gender: string;
        constructor(age: number,
            smoker: string,
            occupational: string,
            gender: string,
            socials: string) {
            this.age = age;
            this.smoker = smoker;
            this.occupational = occupational;
            this.gender = gender;
            this.socials = socials;
        }
    }

    export interface IIncompletePersonRatingFactor {
        withPersonMetaData(data: PersonModule.IPersonModel): IIncompletePersonRatingFactor;
        withMetadata(data: GetQuestionariesModule.IQuestion[]): IIncompletePersonRatingFactor;
        getAge(): IIncompletePersonRatingFactor;
        getSmoker(): IIncompletePersonRatingFactor;
        getOccupational(): IIncompletePersonRatingFactor;
        getGender(): IIncompletePersonRatingFactor;
        getSocials(): IIncompletePersonRatingFactor;
        build(): PersonRatingFactor;
    }

    export class PersonRatingFactorFactory implements IIncompletePersonRatingFactor {


        private age: number;
        private smoker: string;
        private occupational: string;
        private social: string;
        metaData: GetQuestionariesModule.IQuestion[];
        metaDataPerson: PersonModule.IPersonModel;
        gender: string;

        withPersonMetaData(data: PersonModule.IPersonModel): IIncompletePersonRatingFactor {
            this.metaDataPerson = data;
            return this;
        }

        withMetadata(data: GetQuestionariesModule.IQuestion[]): IIncompletePersonRatingFactor {
            this.metaData = data;
            return this;
        }


        getAge(): IIncompletePersonRatingFactor {
            this.age = this.metaDataPerson.PersonAPI.Age;
            return this;

        }

        private getAgeFromDate(dateString) {
            let today = new Date();
            let birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        getSmoker(): IIncompletePersonRatingFactor {
            this.smoker = this.metaDataPerson.SmokingStatus;
            return this;
        }
        getOccupational(): IIncompletePersonRatingFactor {
            this.occupational = this.metaDataPerson.Occupation;
            return this;
        }
        getGender(): IIncompletePersonRatingFactor {
            this.gender = this.metaDataPerson.PersonAPI.Gender;
            return this;
        }

        getSocials(): IIncompletePersonRatingFactor {
            this.social = this.metaDataPerson.SocioEconomicClass;
            return this;
        }
        build(): PersonRatingFactor {
            return new PersonRatingFactor(this.age, this.smoker, this.occupational, this.gender, this.social);
        }
    }
}

export module SocialEconomicModule {

    export interface ClassSocio {
        Salary: number;
        Class: string;
    }

    export class SocialEconomic {
        Education: number;
        Class: ClassSocio[];

        getClass(salary) {
            return this.Class.find(x => x.Salary === salary) ? this.Class.find(x => x.Salary === salary).Class : 'Class_1';
        }
    }
}

export module ExternalQuoteModule {
    export class RatingFactor {
        age: number;
        gender: number;
        smokingStatus: number;
        income: number;
        education: number;
    }

    export class AddtionalInformation {
        hivStatus: string;
        majorConditions: string;
        prescribedMedication: string;
        treatmentLastFourMonths: string;
        upcomingTreatment: string;
    }

    export interface ExtQuoteResult {
        referenceId: string;
        ratingFactor: RatingFactor;
        additionalInformation: AdditionalInformation;
        sumAssuredAmount: number;
        productFriendlyName: string;
        productUid: string;
        pricingOptions: PricingOption[];
        vendorId: string;
        prettyName?: string;
        complimentaryBenefits?: Array<string>;
    }

    export interface AdditionalInformation {
        hivStatus: string;
        majorConditions: string;
        prescribedMedication: string;
        treatmentLastFourMonths: string;
        upcomingTreatment: string;
    }

    export interface PricingOption {
        type: string;
        unitRate: number;
        benefitPatternGUID: string;
        benefitPatternBeforeClaimEscalation: string;
        benefitPatternBeforeClaimOverallEscalation: number | null;
        benefitPatternBeforeClaimAdditionalEscalation: number | null;
        benefitPatternCoverInForceCeaseAge: string;
        premiumPatternGUID: string;
        premiumPatternEscalationType: string;
        premiumPatternOverallEscalation: number | null;
        eventSetGUID: string;
        prettyName?: string;
    }

    export interface RatingFactor {
        age: number;
        gender: number;
        smokingStatus: number;
        income: number;
        education: number;
    }

    export class ExternalQuote {
        id: string;
        referenceId: string;
        pk: string;
        type?: any;
        createdDate: Date;
        lastUpdated: Date;
        unitRate: number;
        ratingFactor: RatingFactor;
        addtionalInformation: AddtionalInformation;
        sumAssuredAmount: number;
        product: string;
        accountID: string;
        constructor() { }
        getAge(): number {
            return this.ratingFactor.age;
        }

        getEducation(): number {
            return this.ratingFactor.education;
        }

        getSmokingStatus(): number {
            return this.ratingFactor.smokingStatus;
        }

        getIncome(): number {
            return this.ratingFactor.income;
        }

        getUnitRate(): number {
            return this.unitRate;
        }

        getSummAssuredValue(): number {
            return this.sumAssuredAmount;
        }
    }

    export class SliderData {
        unitRate: number;
        sumAssuredLimit: number;
    }

    export class ExternalQuoteWithSliderData {
        referenceId: number | string;
        sliderDataDTO: SliderData[];
        productFriendlyName: string;
        productUid: string;
        vendorId: string;
    }
}


export declare module CreateExtQuoteModule {

    export interface RatingFactor {
        age: number;
        gender: number;
        smokingStatus: number;
        income: number;
        education: number;
        sumAssuredLimit?: number;
    }

    export interface AdditionalInformation {
        hivStatus: string;
        majorConditions: string;
        prescribedMedication: string;
        treatmentLastFourMonths: string;
        upcomingTreatment: string;
    }

    export interface CreateExtQuote {
        referenceId?: string;
        unitRate?: number;
        ratingFactor: RatingFactor;
        additionalInformation?: AdditionalInformation;
        sumAssuredAmount: number;
        productFriendlyName: string;
        productUid: string;
        vendorId: string;
    }

}

export interface PersonMainMember {
    GUID: string;
    Identifier: string;
}

export interface GetAllQuotes {
    QuoteUID: string;
    QuoteNumber: string;
    QuoteStatus: string;
    PersonRole: string;
    DateCreated: Date;
    Person_MainMember: PersonMainMember;
    PolicyNumber: string;
    UnderwritingStatus: number;
    IsApproved: boolean;
}

export interface GetAllQuotesResult {
    result: GetAllQuotes[];
}


export declare module RateTableEntriesFlatModule {

    export interface RateTableEntries {
        UID: string;
        BaseRate: number;
        ProductOfFactors: number;
        BenefitPatternGUID: string;
        PremiumPatternGUID: string;
        RatingFactor_SumAssuredLimit_Description: string;
    }

    export interface RateTableEntriesResult {
        result: RateTableEntries[];
    }

}

export declare module RatingFactorResponseNewModule {

    export interface RatingFactor {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface RateTableEntry {
        UID: string;
        BaseRate: number;
        RatingFactorProduct: number;
        ProductGUID: string;
        BenefitPatternGUID: string;
        PremiumPatternGUID: string;
        EventSetShortCode: string;
        RatingFactors: RatingFactor[];
    }

}

export declare module RatingFactorInputResponseModule {

    export interface RatingFactor {
        RatingFactorType: string;
        Description: string;
    }

    export interface RatingFactorInputResponse {
        ProductGUID: string;
        RatingFactors: RatingFactor[];
    }
}


export declare module GetMaxSumAssuredModule {
    export interface ExistingCover {
        existingElevate: number;
        existingOther: number;
        currentElevate: number;
        productSubCategory: string;
    }

    export interface GetMaxSumAssuredInput {
        age: number;
        grossMonthlyIncome: number;
        netMonthlyIncome: number;
        isSelfEmployed: boolean;
        productCategory?: any;
        needsAnalysis?: any;
        houseHoldIncome: number;
        employmentCategory?: any;
        policyReason?: any;
        isAccelerator: boolean;
        mainProductCurrentSumAssured: number | null;
        existingCover: ExistingCover;
        maxBenefitAmount: number;
    }
}

//#region interface and class flat

export interface IQuircAnalysisAfterSubmitting {
    QuircProfileName: string;
    decisionName: string;
    sumUnitLoading: number;
    sumPercentageLoading: number;
    startDate?: string;
    EndDate?: string;
    MendixGenerationName?: string;
    MendixProductUID?: string;
    MendixProductName?: string;
    MendixProductCategory?: string;
    MendixProductDisplayName?: string;
    QuircVersionName?: string;
    AggregationCategory: string;
    productReferences?: any[];
}

export interface IClientDetailsModel {
    email: string;
    cellNumber: string;
    firstName: string;
    surName: string;
    idNumber?: string;
    dob?: Date;
    gender?: any;
    opportunityId?: string;
}

export interface IHttpResponseDetails {
    result: number;
    message?: any;
    sub?: any;
}

export interface IDirectSalesUserDto {
    gender?: any;
    dob: Date;
    cellNumber?: any;
    email?: any;
    identityNumber?: any;
    name: string;
    surname: string;
}

export interface IProductInformationList {
    productId: string;
    productName: string;
    premiumInstallment: number;
    coverAmount: number;
    benefitIncreaseIndex: string;
    benefitIncreases: string;
    benefitTerm: string;
    premiumIncreaseIndex: string;
    premiumIncreases: string;
    premiumTerm: string;
}

export interface IBeneficiaryList {
    beneficiaryId: string;
    productId: string;
    productName: string;
    beneficiaryName: string;
    coveredAmount: number;
    beneficiaryPercentage: number;
}

export interface QuestionariesPageView {
    extId: string;
    questions: GetQuestionariesModule.IQuestion[];
}

export interface IIncrementalQuote {
    GUID: string;
    CalculatedPremium: number;
}

export interface AccountBalance {
    AmmountUnsigned: number;
    DR_CR: string;
    AmountSigned: number;
    AmountRepresentation: string;
}

export interface AccountTransaction {
    TransactionUid: string;
    Amount: number;
    DebitCreditType: string;
    TransactionDate: Date;
    ProcessedDateTime: Date;
    Description: string;
    ContrAccountName: string;
}

export interface Bank {
    BankName: string;
    UniversalBranchCode: string;
    BankLookupName: string;
}

export interface BankResult {
    result: Bank[];
}

export declare module UserMetadataModule {
    export class UserMetadata {
        givenName: string;
        familyName: string;
        idNumber: string;
        birthdate: string;
        gender: string;
        phoneNumber: string;
        email: string;
        tenantName: string;
        medicalSchemeName: string;
        medicalSchemeMembershipNumber: string;
        allowFinancial: string;
        allowHealthcare: string;
        allowWearable: string;
        allowCredit: string;
        marketingMaterialAccepted: string;
        education: string;
        grossIncome: string;
        occupation: string;
        occupationCategory: string;
        smokingStatus: string;
        socioEconomicClass: string;
        physicalAddressLine1: string;
        physicalAddressLine2: string;
        physicalAddressSuburb: string;
        physicalAddressCity: string;
        physicalAddressCode: string;
        maritalStatus: string;
        isVaccinated: string;
        needsAssessment: string;
        needsAssessmentDate: string;
        proofOfIncome: string;
        proofOfIncomeDate: string;
        creditCheck: string;
        creditCheckDate: string;
        netIncome: string;
        needsAssessmentLifeCover: string;
        needsAssessmentSevereIllnessCover: string;
        needsAssessmentIncomeProtection: string;
        needsAssessmentDisabilityImpairmentCover: string;
        confirmedNetIncome: string;
        confirmedGrossIncome: string;
        replaceExistingCover: string;
        hraGoalCompleted: string;
        savingGoalCompleted: string;
        savingGoalOriginalValue: string;
        savingGoalCurrentValue: string;
        savingGoalDate: string;
        elevateMeFasterGoalCompleted: string;
        elevateMeFasterGoalOriginalValue: string;
        elevateMeFasterGoalCurrentValue: string;
        elevateMeFasterGoalDate: string;
        manualRefer: string;
    }
}

export class UserAdditionalDisclosureValue {

    constructor(disclosureType: string, disclosureValue: string, disclosureDataTypeCode: string, disclosureTitle: string, disclosureGroupId: string,
        disclosureOrder: number, enableUserManagementEdit: boolean, hasDisclosureValueOptions: boolean, disclosureValueOptions: string[]
    ) {
        this.disclosureType = disclosureType;
        this.disclosureValue = disclosureValue.getDisclosureValueFromString(disclosureDataTypeCode);
        this.disclosureDataTypeCode = disclosureDataTypeCode;
        this.disclosureTitle = disclosureTitle;
        this.disclosureGroupId = disclosureGroupId;
        this.disclosureOrder = disclosureOrder;
        this.hasDisclosureValueOptions = hasDisclosureValueOptions;
        this.disclosureValueOptions = disclosureValueOptions;
        this.enableUserManagementEdit = enableUserManagementEdit;

        this.valueControl = new FormControl(disclosureValue.getDisclosureValueFromString(disclosureDataTypeCode) , [Validators.required]);
    }

    enableUserManagementEdit: boolean;
    disclosureTitle: string;
    disclosureGroupId: string;
    disclosureType: string;
    disclosureValue: any;
    disclosureDataTypeCode: string;
    disclosureOrder: number;
    hasDisclosureValueOptions: boolean;
    disclosureValueOptions?: string[];
    valueControl: FormControl;
}

export class FullUserAdditionalDisclosuresModel {
    userId: string;
    disclosureGroupValues: KeyedCollection<KeyedCollection<UserAdditionalDisclosureValue[]>>;
}

export interface IUpdateAdditionalDisclosure {
    accessToken: string;
    userId: string;
    updateDisclosures: IUserUpdateAdditionalDisclosure[];
}

export interface IUserUpdateAdditionalDisclosure {
    disclosureGroupId: string;
    disclosureType: string;
    disclosureValue: string;
}


export interface IGroupedExistingCover {
    typeOfCover: string;
    totalCoverAmountKept: number;
}

//#endregion
