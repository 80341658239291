import { HttpClient, HttpRequest } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Available Storage Strategies supported by the API
 */
export var StorageStrategies;
(function (StorageStrategies) {
    StorageStrategies[StorageStrategies["Sharepoint"] = 0] = "Sharepoint";
    StorageStrategies[StorageStrategies["Blob"] = 1] = "Blob";
    StorageStrategies[StorageStrategies["SavingsProofBlob"] = 2] = "SavingsProofBlob";
    StorageStrategies[StorageStrategies["SavingsProofSharepoint"] = 3] = "SavingsProofSharepoint";
    StorageStrategies[StorageStrategies["BankStatementSharepoint"] = 4] = "BankStatementSharepoint";
    StorageStrategies[StorageStrategies["ElevateMeFasterGoalSharepoint"] = 5] = "ElevateMeFasterGoalSharepoint";
    StorageStrategies[StorageStrategies["IncomeProofSharepoint"] = 6] = "IncomeProofSharepoint";
})(StorageStrategies || (StorageStrategies = {}));
/**
 * Available Upload Strategies supported by the uploader.
 */
export var UploadStrategies;
(function (UploadStrategies) {
    // Upload each file individually
    UploadStrategies[UploadStrategies["Sequential"] = 0] = "Sequential";
    // Upload all files at once
    UploadStrategies[UploadStrategies["Simultaneous"] = 1] = "Simultaneous";
})(UploadStrategies || (UploadStrategies = {}));
var FileStorageService = /** @class */ (function () {
    function FileStorageService(httpClient) {
        this.httpClient = httpClient;
    }
    /**
     * Method to submit the upload request to the server using POST
     * @param form The Form Data to be sent to the server.
     */
    FileStorageService.prototype.upload = function (form) {
        var req = new HttpRequest('POST', this.prepareUrl('Upload'), form, this.prepareHeaders());
        return this.httpClient.request(req);
    };
    /**
     * Method to fetch all files related to the client from a specific strategy.
     * @param request The request object to be sent.
     */
    FileStorageService.prototype.fetchFiles = function (request) {
        var req = new HttpRequest('POST', this.prepareUrl('Fetch'), request, this.prepareHeaders());
        // return this.httpClient.request<FetchFilesResponse[]>(req);
        return this.httpClient.post(this.prepareUrl('Fetch'), request, this.prepareHeaders()).pipe(map(function (result) { return result.result; }));
    };
    // getCoverProposals(brokerGuid: string): Observable<Array<string>> {
    //     let url = `${this.appServiceBaseUrl}/api/services/app/CoverProposals/GetCoverProposals?brokerGuid=${brokerGuid}`;
    //     return this.http.get<{ result: Array<string> }>(url, {}).pipe(map(result => result.result));
    // }
    /**
     * Method to download a file from the server
     * @param request The details of the file to be downloaded
     */
    FileStorageService.prototype.downloadFile = function (request) {
        request.fileName = encodeURIComponent(request.fileName);
        this.httpClient.post(this.prepareUrl('Download'), request, { responseType: 'blob' }).subscribe(function (blob) {
            var downloadUrl = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = downloadUrl;
            a.download = request.fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
        });
    };
    /**
     * Method to delete a file from the server
     * @param request The details of the file to be deleted
     */
    FileStorageService.prototype.deleteFile = function (request) {
        var url = this.prepareUrl('Remove');
        var httpRequest = new HttpRequest('DELETE', url, request, this.prepareHeaders());
        return this.httpClient.request(httpRequest).pipe(map(function (response) {
            if (response.type === 4) { // HttpEventType.Response
                return response;
            }
        }));
    };
    /**
     * Prepares the connection URL based on the supplied endpoint.
     * @param endpoint The relative path within the service.
     * @private
     */
    FileStorageService.prototype.prepareUrl = function (endpoint) {
        return AppConsts.remoteServiceBaseUrl + "/api/services/app/FileStorage/" + endpoint;
    };
    /**
     * Prepares the common headers to be used in making a request to the server.
     * @private
     */
    FileStorageService.prototype.prepareHeaders = function () {
        var _a;
        return _a = {},
            _a[abp.multiTenancy.tenantIdCookieName] = abp.multiTenancy.getTenantIdCookie(),
            _a['Authorization'] = 'Bearer ' + abp.auth.getToken(),
            _a.reportProgress = true,
            _a;
    };
    FileStorageService.ngInjectableDef = i0.defineInjectable({ factory: function FileStorageService_Factory() { return new FileStorageService(i0.inject(i1.HttpClient)); }, token: FileStorageService, providedIn: "root" });
    return FileStorageService;
}());
export { FileStorageService };
